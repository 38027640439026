











import { Component, Vue } from "vue-property-decorator";

@Component
export default class TermsAndConditionsModal extends Vue {
  text = `
    <p>${this.$t("TermsOfService_Section1_Description1")}</p>
    <h2>${this.$t("TermsOfService_Section2_Title")}</h2>
    <p>${this.$t("TermsOfService_Section2_Description1")}</p>
    <h2>${this.$t("TermsOfService_Section3_Title")}</h2>
    <p>${this.$t("TermsOfService_Section3_Description1")}</p>
    <h2>${this.$t("TermsOfService_Section4_Title")}</h2>
    <p>${this.$t("TermsOfService_Section4_Description1")}</p>
    <h2>${this.$t("TermsOfService_Section5_Title")}</h2>
    <p>${this.$t("TermsOfService_Section5_Description1")}</p>
    <h2>${this.$t("TermsOfService_Section6_Title")}</h2>
    <h2>${this.$t("TermsOfService_Section6_Subtitle1")}</h2>
    <p>${this.$t("TermsOfService_Section6_Description1")}</p>
    <h2>${this.$t("TermsOfService_Section6_Subtitle2")}</h2>
    <p>${this.$t("TermsOfService_Section6_Description2")}</p>
    <h2>${this.$t("TermsOfService_Section6_Subtitle3")}</h2>
    <p>${this.$t("TermsOfService_Section6_Description3")}</p>
    <h2>${this.$t("TermsOfService_Section6_Subtitle4")}</h2>
    <p>${this.$t("TermsOfService_Section6_Description4")}</p>
    <h2>${this.$t("TermsOfService_Section6_Subtitle5")}</h2>
    <p>${this.$t("TermsOfService_Section6_Description5")}</p>
    <h2>${this.$t("TermsOfService_Section6_Subtitle6")}</h2>
    <p>${this.$t("TermsOfService_Section6_Description6")}</p>
    <h2>${this.$t("TermsOfService_Section6_Subtitle7")}</h2>
    <p>${this.$t("TermsOfService_Section6_Description7")}</p>
    <h2>${this.$t("TermsOfService_Section6_Subtitle8")}</h2>
    <p>${this.$t("TermsOfService_Section6_Description8")}</p>
    <h2>${this.$t("TermsOfService_Section7_Title")}</h2>
    <p>${this.$t("TermsOfService_Section7_Description1")}</p>
    <h2>${this.$t("TermsOfService_Section8_Title")}</h2>
    <h3>${this.$t("TermsOfService_Subsection1_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection1_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection2_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection2_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection3_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection3_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection4_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection4_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection5_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection5_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection6_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection6_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection7_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection7_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection8_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection8_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection9_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection9_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection10_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection10_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection11_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection11_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection12_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection12_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection13_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection13_Description")}</p>
    <h3>${this.$t("TermsOfService_Subsection14_Title")}</h3>
    <p>${this.$t("TermsOfService_Subsection14_Description")}</p>
  `.replaceAll("\\n", "<br />");
}
